<template>
  <div>
    <el-dialog
      title="物资明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form
        :model="form"
        status-icon
        :rules="rules"
        ref="form"
        label-width="60px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称" prop="goodsId">
              <base-select
                @input="setGoodsInfo"
                info="Goods"
                v-model="form.goodsId"
              ></base-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格">
              <el-input
                v-model="form.goodsSpec"
                readonly="readonly"
                placeholder="规格"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单位">
              <el-input
                v-model="form.goodsUnit"
                readonly="readonly"
                placeholder="单位"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                type="number"
                v-model="form.quantity"
                placeholder="数量"
                clearable
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input
            v-model="form.remark"
            placeholder="备注"
            type="textarea"
            :rows="2"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)">保存关闭</el-button>
            <el-button type="success" @click="save(true)">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import guid from "@/common/guid.js";
import BaseSelect from "@/components/BaseSelect.vue";
export default {
  props: ["item"],
  components: {
    BaseSelect,
  },
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        goodsId: [this.$g.required],
        quantity: [this.$g.required, this.$g.notZero],
      },
    };
  },
  watch: {
    item: {
      handler: function (row) {
        if (row.id) {
          this.form = { ...row };
        } else {
          this.initForm();
        }
        this.visible = true;
      },
      deep: true,
    },
  },
  methods: {
    initForm() {
      this.form = {
        id: guid(),
        goodsId: "",
        goodsCode: "",
        goodsName: "",
        goodsSpec: "",
        goodsUnit: "",
        remark: "",
      };
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    setGoodsInfo(goodsId) {
      if (goodsId) {
        this.$get("Goods/GetGoodsInfoById", {
          id: goodsId,
        }).then((r) => {
          this.form.goodsCode = r.goodsCode;
          this.form.goodsName = r.goodsName;
          this.form.goodsSpec = r.goodsSpec;
          this.form.goodsUnit = r.goodsUnit;
        });
      } else {
        this.form.goodsCode = "";
        this.form.goodsName = "";
        this.form.goodsSpec = "";
        this.form.goodsUnit = "";
      }
    },
    save(isContinue) {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.$emit("success", this.form);

        if (isContinue) {
          this.initForm();
        } else {
          this.visible = false;
        }
      });
    },
  },
};
</script>

<style>
</style>