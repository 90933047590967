<template>
  <div>
    <div class="bill-title">采购计划</div>
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="80px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="单据编号" prop="billCode">
            <el-input v-model="form.billCode" placeholder="单据编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              style="width: 150px"
              @change="getBillCode(12)"
              v-model="form.billDate"
              placeholder="单据日期"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="选择项目" prop="projectId">
            <project-select
              style="width: 100%"
              v-model="form.projectId"
              :canAdd="true"
              :state="[1, 2]"
              :addId="addProjectId"
              @addClick="
                () => {
                  this.projectItem = {};
                }
              "
            ></project-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="摘要">
        <el-input v-model="form.remark" placeholder="摘要" clearable></el-input>
      </el-form-item>
      <el-form-item label="单据附件">
        <BillAttach :attachId="form.attachId"></BillAttach>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <strong>物资明细</strong>
        </template>
        <el-button-group>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            @click="detail = {}"
          ></el-button>
          <el-button size="mini" type="success" @click="multiple = {}">
            <span class="iconfont iconduoxuan" style="font-size: 12px"></span>
          </el-button>
        </el-button-group>
      </el-form-item>
      <div style="margin: -8px 0px 8px 80px">
        <el-table :data="detailList" @row-dblclick="editDetail" stripe border>
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column
            label="编号"
            prop="goodsCode"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="名称"
            prop="goodsName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="规格"
            prop="goodsSpec"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="单位"
            prop="goodsUnit"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="数量">
            <template slot-scope="scope">{{
              scope.row.quantity | thousands
            }}</template>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="remark"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" width="65">
            <template slot-scope="scope">
              <el-tag
                v-if="!scope.row.isStop"
                type="danger"
                effect="dark"
                @click="deleteDetail(scope.row)"
                >移除</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-form-item>
        <div v-if="canntEditMsg" style="display: inline">
          <span style="color: red; margin-right: 10px"
            >{{ canntEditMsg }} 单据不可编辑</span
          >
        </div>

        <el-button-group v-else>
          <el-button type="info" @click="save(false)">保存关闭</el-button>
          <el-button type="success" @click="save(true)">保存新增</el-button>
          <el-button type="warning" @click="cancel">取消关闭</el-button>
          <el-button type="danger" @click="deleteBill" v-if="form.id&&hasPermission('DeleteBill')"
            >删除单据</el-button
          >
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <operator-box :item="form"></operator-box>
      </el-form-item>
    </el-form>

    <div>
      <add-project
        :item="projectItem"
        @success="
          (id) => {
            this.form.projectId = id;
          }
        "
      ></add-project>
      <goods-detail :item="detail" @success="updateDetail"></goods-detail>
      <multiple-detail :item="multiple" @success="addArray"></multiple-detail>
    </div>
  </div>
</template>

<script>
import billcode from "@/common/billcodeMixins.js";
import ProjectSelect from "@/components/ProjectSelect.vue";
import AddProject from "@/views/project/Item.vue";

import GoodsDetail from "./GoodsDetail.vue";
import MultipleDetail from "./MultipleDetail.vue";

import OperatorBox from "@/components/OperatorBox";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  mixins: [billcode],
  components: {
    AddProject,
    ProjectSelect,
    GoodsDetail,
    MultipleDetail,
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      routeTab: "采购计划",
      form: {},
      rules: {
        billCode: [this.$g.required],
        billDate: [this.$g.required],
        projectId: [this.$g.required],
      },
      detailList: [],
      detail: {},
      multiple: {},

      addProjectId: null, //不可用项目Id(编辑时要显示)
      canntEditMsg: "", //不可编辑的提示信息

      projectItem: {},
    };
  },
  computed: {},
  mounted() {
    this.init(this.$route.query.id);
  },
  methods: {
    //初始化
    init(id) {
      this.addProjectId = null;
      this.canntEditMsg = "";

      if (id) {
        this.editInit(id);
      } else {
        this.addInit();
      }
    },
    //新增初始化
    addInit() {
      this.form = {
        billCode: "",
        billDate: new Date(),
        projectId: "",
        attachId: guid(),
      };
      this.detailList = [];
      this.getBillCode(12);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    //编辑初始化
    editInit(id) {
      this.$get("GoodsPlan/Detail", {
        id,
      }).then((r) => {
        this.routeTab = r.bill.billCode;
        this.form = { ...r.bill };
        this.detailList = [...r.detail];

        this.addProjectId = r.addProjectId;
        this.canntEditMsg = r.canntEditMsg;
      });
    },

    //选择物资后确定
    updateDetail(row) {
      let index = -1;
      this.detailList.forEach((item, i) => {
        if (item.id == row.id) {
          index = i;
        }
      });
      if (index == -1) {
        //添加
        this.detailList.push(row);
      } else {
        //修改
        this.detailList.splice(index, 1, row);
      }
    },
    //多选物资
    addArray(array) {
      array.forEach((item) => {
        this.detailList.push(item);
      });
    },
    //双击明细，编辑
    editDetail(row) {
      if (row.isStop) {
        return;
      }
      this.detail = { ...row };
    },
    // 移除明细
    deleteDetail(row) {
      let index = this.detailList.findIndex((d) => {
        return d.id == row.id;
      });
      this.detailList.splice(index, 1);
    },
    //保存
    save(isContinue) {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.detailList.length == 0) {
          this.$message.error("请选择物资明细");
          return;
        }

        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let bill = { ...this.form };
        let detail = [...this.detailList];
        let url = "";
        if (this.form.id) {
          url = "GoodsPlan/Edit";
        } else {
          url = "GoodsPlan/Add";
        }
        this.$post(url, {
          bill,
          detail,
        })
          .then((r) => {
            this.$message.success("操作成功！");
            if (isContinue) {
              this.$tabs.close({
                to: {
                  name: "GoodsPlan",
                  query: {},
                },
                refresh: true,
              });
            } else {
              this.$tabs.close();
            }
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    //删除单据
    deleteBill() {
      this.$confirm("将删除此计划单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("GoodsPlan/Delete?id=" + this.form.id)
            .then((r) => {
              this.$g.deleteAttach(this.form.attachId);
              this.$message.success("操作成功！");
              this.$tabs.close();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    cancel() {
      if (!this.form.id) {
        this.$g.deleteAttach(this.form.attachId);
      }
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>